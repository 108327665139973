import { Background } from '@/Components/Background';
import { BoxWithTitle, ConfigureLabelContainer, LabelButton, LabelRow, Subtitle, Title } from './styled';
import ModalLoading from '@/Components/ModalLoading';
import { Header } from '@/Components/Header';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from '@/Services/api/ApiService';
import { EventResponse } from '@/Services/api/models/eventModel';
import { Etiquetas, IEtiquetas } from '@/util/etiquetas';
import { Form } from 'react-bootstrap';
import { TButton } from '@/Components/TButton';
import { EventTitle } from '@/Pages/Participants/Consultar/styled';
import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import { toBase64 } from '@/util/base64';
import { imageToZ64 } from '@/util/convertImage';

export function ConfigureLabel() {
    const [isLoading, setIsLoading] = useState(false);
    const [eventInfo, setEventInfo] = useState<EventResponse>();

    const [labelDesign, setLabelDesign] = useState('');
    const [labelResolution, setLabelResolution] = useState('');
    const [labelImage, setLabelImage] = useState('')
    const printerModels = ['ZEBRA','ELGIN']
    const [printerModel, setPrinterModel] = useState<string>('')
    const [labelQty, setLabelQty] = useState<number>(1)

    const { eventId } = useParams();
    const navigate = useNavigate();

    const getEventInfo = async () => {
        try {
            setIsLoading(true);

            if (!eventId) {
                throw new Error('Evento não informado na URL');
            }

            const eventInfo = await ApiService.getEventById(+eventId);

            setEventInfo(eventInfo);
            setLabelDesign(findLabelId(eventInfo.labelResolution, eventInfo.labelConfiguration));
            setLabelResolution(eventInfo.labelResolution);
            setPrinterModel(eventInfo.printerModel);
            setLabelQty(eventInfo?.labelPrintCount)
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const findLabelId = (size: string, zplCode: string): string | undefined => {
        for (const label of Etiquetas) {
            const sizes = label.sizes;
            for (const labelSize in sizes) {
                if (sizes.hasOwnProperty(labelSize)) {
                    if (size === labelSize && zplCode === sizes[labelSize]) {
                        return label.id;
                    }
                }
            }
        }
        return undefined;
    }

    const changeLabelConfiguration = async () => {
        try {
            setIsLoading(true);

            if (
                labelDesign === findLabelId(labelResolution, eventInfo?.labelConfiguration) &&
                labelResolution === eventInfo.labelResolution &&
                printerModel === eventInfo.printerModel &&
                labelQty === eventInfo?.labelPrintCount

            ) {
                toast.success('Etiqueta alterada com sucesso');
                navigate('/');
                return;
            }

            if (!labelDesign || !labelResolution) {
                throw new Error('Nenhuma etiqueta selecionada');
            }

            if (!eventInfo) {
                throw new Error('Nenhum evento encontrado na URL');
            }
            const backgroundImage = await ApiService.getBackgroundImage(Number(eventId))
            await ApiService.upInsertEvent({
                ...eventInfo,
                backgroundImage: backgroundImage,
                labelConfiguration: Etiquetas.find(l => l.id.includes(labelDesign)).sizes?.[labelResolution]?.replaceAll('[[IMAGE_ZPL]]',labelImage),
                labelResolution: labelResolution,
                printerModel: printerModel,
                labelPrintCount: labelQty
            });

            toast.success('Etiqueta alterada com sucesso');
            navigate('/');
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
            const base64 = await toBase64(selectedFile)
            const res = await imageToZ64(base64,{rotate: 'L'})
            setLabelImage(`^GFA,${res.length},${res.length},${res.rowlen},${res.z64}`)
        }
    }

    useEffect(() => {
        getEventInfo();
    }, []);

    return (
        <Background>
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Configurar Etiqueta' />
            <EventTitle>
                {' '}
                <span> Evento: </span> {eventInfo?.description} ({eventInfo?.id}){' '}
            </EventTitle>
            <WhiteBackground>
                <BoxWithTitle>
                    <Title> Configuração do Design da Etiqueta </Title>
                    <ConfigureLabelContainer>
                        <LabelRow>
                            <Subtitle> Etiqueta </Subtitle>
                            <Subtitle> Tamanhos disponíveis </Subtitle>
                            <Subtitle> Campos </Subtitle>
                        </LabelRow>
                        {Etiquetas.map((label, index) => {
                            return (
                                <LabelRow style={{backgroundColor: index%2===0? '#D9D9D9' : '#FFF'}}>
                                    <Form.Check
                                        type={'radio'}
                                        label={<img src={label.imagePath} alt='' />}
                                        name='labelDesign'
                                        checked={labelDesign === label.id}
                                        onChange={() => setLabelDesign(label.id)}
                                    />
                                    <Form.Label>{Object.keys(label?.sizes)?.map((size, index) => index === Object.keys(label?.sizes)?.length - 1? `${size}` : `${size}, `)}</Form.Label>
                                    <Form.Label>{label?.fields}</Form.Label>
                                </LabelRow>
                            );
                        })}
                        <LabelRow>
                            <Form.Check
                                    type={'radio'}
                                    label={'Sem etiqueta'}
                                    name='labelDesign'
                                    checked={labelDesign === 'No label'}
                                    onChange={() => {setLabelDesign('No label') ; setLabelResolution('No label')}}
                            />
                        </LabelRow>
                    </ConfigureLabelContainer>
                </BoxWithTitle>
                <BoxWithTitle>
                    <Title> Configuração da Resolução da Etiqueta </Title>
                    {Object.keys(Etiquetas.find(label => label.id === labelDesign)?.sizes || []).map((value, index) => {
                        return (
                            <Form.Check
                                type={'radio'}
                                label={value}
                                name='labelResolution'
                                checked={labelResolution === value}
                                onChange={() => setLabelResolution(value)}
                            />
                        );
                    })}
                </BoxWithTitle>
                {labelDesign === 'label11' && 
                    <BoxWithTitle>
                        <Title> Configuração do Logo da Etiqueta </Title>
                        <Form.Control type='file' onChange={handleUploadImage}/>
                    </BoxWithTitle>
                }
                <BoxWithTitle>
                    <Title>Modelo da Impressora</Title>
                    <Form.Select value={printerModel} onChange={(e) => setPrinterModel(e.target.value)}>
                            {printerModels.map((item) => (
                                <option key={item} value={item}>
                                    {' '}
                                    {item}
                                </option>
                            ))}
                        </Form.Select>
                </BoxWithTitle>
                <BoxWithTitle>
                    <Title>Quantidade de etiquetas impressas</Title>
                    <Form.Control value={labelQty} type='number' min={1} onChange={(e) => setLabelQty(parseInt(e.target.value))} />
                </BoxWithTitle>
                <LabelButton onClick={changeLabelConfiguration}>
                    {' '}
                    Alterar Configurações{' '}
                </LabelButton>
            </WhiteBackground>
        </Background>
    );
}
