import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    AutoAttendantButton,
    AutoAttendantContainer,
    AutoAttendantContent,
    CPanelButton,
    HiddenInput,
    Title,
    TranslateButton,
} from './styled';
import ModalLoading from '@/Components/ModalLoading';
import { useToken } from '@/Components/UseToken';
import { EventResponse } from '@/Services/api/models/eventModel';
import ApiService from '@/Services/api/ApiService';
import { toast } from 'react-toastify';
import { AiFillSetting } from 'react-icons/ai';
import { useAutoAttendantNavigate } from '@/Components/CustomNavigate/autoattendant';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { useForm } from 'react-hook-form';
export function AutoAttendant() {
    const { token } = useToken();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eventInfos, setEventInfos] = useState<EventResponse>();
    const [backgroundImage, setBackgroundImage] = useState('');
    const [english, setEnglish] = useState<boolean>(false)

    const navigate = useAutoAttendantNavigate(location.state?.backgroundImage ?? backgroundImage);

    const {setValue, watch, handleSubmit, register} = useForm<{document: string}>()

     //provisório
     const userInfoString = localStorage.getItem('userInfo');
     const userInfo: UsersResponse = JSON.parse(userInfoString) ?? {}

    const getEventInfo = async () => {
        try {
            setIsLoading(true);
            const event = await ApiService.getEventById(userInfo?.events?.[0] ?? 0);
            setEventInfos(event);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const loadEventImage = async () => {
        try {
            setIsLoading(true);
            //provisório
            const userInfoString = localStorage.getItem('userInfo');
            const userInfo: UsersResponse = JSON.parse(userInfoString) ?? {}
            const backgroundImage = await ApiService.getBackgroundImage(userInfo?.events?.[0] ?? 0);
            setBackgroundImage(backgroundImage);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getEventInfo();
        loadEventImage();
    }, []);

    const handleGoCheckout = () => {
        const path  = `/autoattendant/checkin/${english? 'en' : 'pt'}`
        navigate(path);
    };

    const handleGoCreate = () => {
        const path = `/autoattendant/registrarParticipante/${english? 'en' : 'pt'}`
        navigate(path);
    };

    const handleQRCodeRead = (data: any) => {
        navigate(`/autoattendant/checkin/${english? 'en' : 'pt'}?document=${watch('document')?.toString()}`)
    }

    return (
        <AutoAttendantContainer backgroundUrl={backgroundImage ?? ''}>
            <HiddenInput>
                <form onSubmit={handleSubmit(handleQRCodeRead)}>
                        <input onChange={(e) => setValue('document', e.target.value)} autoFocus {...register('document')}/>
                </form>
            </HiddenInput>
            <ModalLoading isActive={isLoading} />
            <Title> {eventInfos?.removeTitleLabel && eventInfos?.description} </Title>
            <AutoAttendantContent>
                {eventInfos?.showCheckinButton &&
                    <AutoAttendantButton onClick={handleGoCheckout}>
                        {' '}
                        {english? 'Check-in' : 'Realizar Check-In'}
                    </AutoAttendantButton>
                }
                {eventInfos?.allowParticipantAutoRegistration && (
                    <AutoAttendantButton onClick={handleGoCreate}>
                        {' '}
                        {english? 'New Register' : 'Novo Cadastro'}
                    </AutoAttendantButton>
                )}
            </AutoAttendantContent>
            <CPanelButton
                onClick={() => {
                    if (token?.user.userGroup === 'ADMIN') {
                        navigate('/eventos/consultar/');
                        return;
                    }

                    navigate('/login');
                }}
            >
                {' '}
                <AiFillSetting size={25} color={'#000000'} />{' '}
            </CPanelButton>
            <TranslateButton
                onClick={() => {
                    setEnglish(!english)
                }}
            >
                <img src={english? '/assets/images/Brazil.png' : '/assets/images/UK.png'} />
            </TranslateButton>
        </AutoAttendantContainer>
    );
}
