import { EventResponse } from '@/Services/api/models/eventModel';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import { AppConfigLabel, Etiquetas, IEtiquetas, ProtocoloMalex } from './etiquetas';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import ApiService from '@/Services/api/ApiService';
import { MalexLabelData } from '@/Services/api/models/malexModels';

export const handlePrintTicket = async (
    event: EventResponse,
    participant: GetParticipantResponse,
    malex?: boolean,
    malexData?: MalexLabelData,
) =>
    new Promise(async (resolve, reject) => {
        const categories:ParticipantCategories[] = await ApiService.getParticipantCategories()
        const etiqueta = malex? ProtocoloMalex.sizes['10x6'] : event.labelConfiguration
        const printer = event.printerModel ?? 'ZEBRA'

        if (!etiqueta) {
            return reject(new Error('Nenhuma etiqueta configurada'));
        }
        let zebraCode = etiqueta
        
        zebraCode = zebraCode?.replaceAll('[[EMPRESA]]', participant.companyName?.toString().slice(0,40) ?? '');

        zebraCode = zebraCode?.replaceAll('[[CARGO]]', participant.companyPosition?.toString().slice(0,40) ?? '');

        zebraCode = zebraCode?.replaceAll('[[QRCODE]]', participant.id?.toString());

        zebraCode = zebraCode?.replaceAll(
            '[[NOME_1]]',
            `${participant.name} ${
                !participant.lastName?.toString() ? '' : participant.lastName?.toString().split(' ').at(-1)
            }`,
        );

        zebraCode = zebraCode?.replaceAll('[[NOME_2]]', participant.name?.toString())
        zebraCode = zebraCode?.replaceAll('[[NOME_3]]', (!participant.lastName?.toString() ? '' : participant.lastName?.toString().split(' ').at(-1)))

        zebraCode = zebraCode?.replaceAll('[[CATEGORIA]]', categories.find((category) => category.id === participant.categoryId)?.categoryName.toUpperCase())
        zebraCode = zebraCode?.replaceAll('[[ESTADO]]', participant.state)

        if(malex) {
            const items = malexData.items.map((item,index) => {
                return `^CI27^FT52,${302+index*30}^A@N,37,38,ARI000.FNT^FH^CI28^FD${item}^FS`
            })
            const itemsString = items.join('')
            zebraCode = zebraCode?.replace('[[ITENS]]', itemsString)
            zebraCode = zebraCode?.replaceAll('[[USUARIO]]', malexData.user)
            zebraCode = zebraCode?.replaceAll('[[DATA]]', malexData.dateTime)
            zebraCode = zebraCode?.replaceAll('[[EVENTO]]', event.description)
        }

        console.log(`${participant?.name} ${participant.lastName}`)

        for(let i = 0; i<event.labelPrintCount;i++) {
            if(printer === 'ELGIN') {
                const elgin = 'http://127.0.0.1:2001/eti/v1/write'
                zebraCode = zebraCode?.replaceAll('.FNT','.TTF')
                try{
                    fetch(elgin, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        method: 'POST',
                        body: JSON.stringify({dados: zebraCode})
                    })
                    .then(res => res.json())
                    .then((response) => {
                        if(response.mensagem === 'SUCESSO') {
                            return resolve('Impressão realizada');
                        }
                    })
                } catch (error) {
                    return reject(new Error('Erro ao se comunicar com a impressora'))
                }
            }
    
            if(printer === 'ZEBRA') {
                const BrowserPrint = (window as any).BrowserPrint;
                if (!BrowserPrint) {
                    return reject(new Error('Falha ao se comunicar com a impressora'));
                }
        
                BrowserPrint.getLocalDevices((devices: any) => {
                    if (!devices.printer) {
                        reject(new Error('Erro ao se comunicar com a impressora'));   
                    }
                    if(event?.id === 23) {
                        const voucherCodes = [
                            zebraCode,
                            '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FO67,31^GFA,877,2205,21,:Z64:eJy9VbGK4zAQHQkbhIslC1ZvXBkV+QYF1r2L6H8GV0KFv0G4Mir8AVvctyxbmS38DTfKbpaL7LuEg7sXC4fJy/PzaEYDgPAgOH0eQ4GA3O78wNJAjVAV4w5TdklgQlEdP0jWjuKWaSCDeF3RQAHqFblz060HKfWtB4vKhx8ehBUWxWSdxRjWkJvk6R7qqn71t0ENB2ApcyFNuyJYAggh8MrMEiYfySlX1Xde+eftcLlumB4nrELURPJpSRSjJr1zkiVeg8KiSSwdfs3OF8QEA272fo/Jm2gyxR4zBDenivvMWtWK3jrKXqSry/fIlNAxRqt71l0OJbhJOTz261P/vg4fx/f1+EbJjUzTmryldZYnuozuVV1DcMGNtIIa4+WjOOtymbd5lsuS6XPe6uBUwGUYho8Bh3nul3no3zS5ZLrMW2MyWZpTZ3Km66YYuVfcxb9cNEMfNVlkSiOl7Iw+y5ZpZQvHl+PTMA/DvCx2dmv/FsuSdcSSeV52XVae2lYrQRsdCpIjlxNZDWPAy46b3JSSVmcIJwMiNtHq1uPRrfOyDsvar3hJZ8m6UrJzeY4+4Qyi4s1NPr/uWwi/05jZtjFp3yvw+xobJsK0F9d7zG/N6uqR7TGpNGMZeycECGrowhXuytRZwow6fXFpH2qmWo24bxR/l5X/i+0xynY3wAUqD0yiLxp0ejbyJjRU/Knmc3qQEKxAITbRPZM99b3aUtlhczz10+RCmvPs8HLQOmG6MdQqPPL4fwGnpsbZ6fOQ/yOKqqJSHevqLtNaR5qI9zUfBp+iSYK/y6x78BXNpvuaNOEczTm7N5X/EjSKsRfe9TSV/J+pnqxyzzGO0Xuy1O9UdTQ5BfwEMG3QBA==:54E5^FT266,65^A@N,39,38,ARI001.FNT^FB373,1,10,C^FH^CI28^FDVoucher^FS^CI27^FT266,114^A@N,39,38,ARI001.FNT^FB373,1,10,C^FH^CI28^FDJantar - 20/09^FS^CI27^FT1,244^A@N,39,38,ARI001.FNT^FB638,1,10,C^FH^CI28^FD[[NOME_1]]^FS^CI27^PQ1,,,Y^XZ',
                            '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FO67,31^GFA,877,2205,21,:Z64:eJy9VbGK4zAQHQkbhIslC1ZvXBkV+QYF1r2L6H8GV0KFv0G4Mir8AVvctyxbmS38DTfKbpaL7LuEg7sXC4fJy/PzaEYDgPAgOH0eQ4GA3O78wNJAjVAV4w5TdklgQlEdP0jWjuKWaSCDeF3RQAHqFblz060HKfWtB4vKhx8ehBUWxWSdxRjWkJvk6R7qqn71t0ENB2ApcyFNuyJYAggh8MrMEiYfySlX1Xde+eftcLlumB4nrELURPJpSRSjJr1zkiVeg8KiSSwdfs3OF8QEA272fo/Jm2gyxR4zBDenivvMWtWK3jrKXqSry/fIlNAxRqt71l0OJbhJOTz261P/vg4fx/f1+EbJjUzTmryldZYnuozuVV1DcMGNtIIa4+WjOOtymbd5lsuS6XPe6uBUwGUYho8Bh3nul3no3zS5ZLrMW2MyWZpTZ3Km66YYuVfcxb9cNEMfNVlkSiOl7Iw+y5ZpZQvHl+PTMA/DvCx2dmv/FsuSdcSSeV52XVae2lYrQRsdCpIjlxNZDWPAy46b3JSSVmcIJwMiNtHq1uPRrfOyDsvar3hJZ8m6UrJzeY4+4Qyi4s1NPr/uWwi/05jZtjFp3yvw+xobJsK0F9d7zG/N6uqR7TGpNGMZeycECGrowhXuytRZwow6fXFpH2qmWo24bxR/l5X/i+0xynY3wAUqD0yiLxp0ejbyJjRU/Knmc3qQEKxAITbRPZM99b3aUtlhczz10+RCmvPs8HLQOmG6MdQqPPL4fwGnpsbZ6fOQ/yOKqqJSHevqLtNaR5qI9zUfBp+iSYK/y6x78BXNpvuaNOEczTm7N5X/EjSKsRfe9TSV/J+pnqxyzzGO0Xuy1O9UdTQ5BfwEMG3QBA==:54E5^FT266,65^A@N,39,38,ARI001.FNT^FB373,1,10,C^FH^CI28^FDVoucher^FS^CI27^FT266,114^A@N,39,38,ARI001.FNT^FB373,1,10,C^FH^CI28^FDAlmoço - 21/09^FS^CI27^FT1,244^A@N,39,38,ARI001.FNT^FB638,1,10,C^FH^CI28^FD[[NOME_1]]^FS^CI27^PQ1,,,Y^XZ',
                            '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FO67,31^GFA,877,2205,21,:Z64:eJy9VbGK4zAQHQkbhIslC1ZvXBkV+QYF1r2L6H8GV0KFv0G4Mir8AVvctyxbmS38DTfKbpaL7LuEg7sXC4fJy/PzaEYDgPAgOH0eQ4GA3O78wNJAjVAV4w5TdklgQlEdP0jWjuKWaSCDeF3RQAHqFblz060HKfWtB4vKhx8ehBUWxWSdxRjWkJvk6R7qqn71t0ENB2ApcyFNuyJYAggh8MrMEiYfySlX1Xde+eftcLlumB4nrELURPJpSRSjJr1zkiVeg8KiSSwdfs3OF8QEA272fo/Jm2gyxR4zBDenivvMWtWK3jrKXqSry/fIlNAxRqt71l0OJbhJOTz261P/vg4fx/f1+EbJjUzTmryldZYnuozuVV1DcMGNtIIa4+WjOOtymbd5lsuS6XPe6uBUwGUYho8Bh3nul3no3zS5ZLrMW2MyWZpTZ3Km66YYuVfcxb9cNEMfNVlkSiOl7Iw+y5ZpZQvHl+PTMA/DvCx2dmv/FsuSdcSSeV52XVae2lYrQRsdCpIjlxNZDWPAy46b3JSSVmcIJwMiNtHq1uPRrfOyDsvar3hJZ8m6UrJzeY4+4Qyi4s1NPr/uWwi/05jZtjFp3yvw+xobJsK0F9d7zG/N6uqR7TGpNGMZeycECGrowhXuytRZwow6fXFpH2qmWo24bxR/l5X/i+0xynY3wAUqD0yiLxp0ejbyJjRU/Knmc3qQEKxAITbRPZM99b3aUtlhczz10+RCmvPs8HLQOmG6MdQqPPL4fwGnpsbZ6fOQ/yOKqqJSHevqLtNaR5qI9zUfBp+iSYK/y6x78BXNpvuaNOEczTm7N5X/EjSKsRfe9TSV/J+pnqxyzzGO0Xuy1O9UdTQ5BfwEMG3QBA==:54E5^FT266,65^A@N,39,38,ARI001.FNT^FB373,1,10,C^FH^CI28^FDVoucher^FS^CI27^FT266,114^A@N,39,38,ARI001.FNT^FB373,1,10,C^FH^CI28^FDJantar - 21/09^FS^CI27^FT1,244^A@N,39,38,ARI001.FNT^FB638,1,10,C^FH^CI28^FD[[NOME_1]]^FS^CI27^PQ1,,,Y^XZ',
                            '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FO67,31^GFA,877,2205,21,:Z64:eJy9VbGK4zAQHQkbhIslC1ZvXBkV+QYF1r2L6H8GV0KFv0G4Mir8AVvctyxbmS38DTfKbpaL7LuEg7sXC4fJy/PzaEYDgPAgOH0eQ4GA3O78wNJAjVAV4w5TdklgQlEdP0jWjuKWaSCDeF3RQAHqFblz060HKfWtB4vKhx8ehBUWxWSdxRjWkJvk6R7qqn71t0ENB2ApcyFNuyJYAggh8MrMEiYfySlX1Xde+eftcLlumB4nrELURPJpSRSjJr1zkiVeg8KiSSwdfs3OF8QEA272fo/Jm2gyxR4zBDenivvMWtWK3jrKXqSry/fIlNAxRqt71l0OJbhJOTz261P/vg4fx/f1+EbJjUzTmryldZYnuozuVV1DcMGNtIIa4+WjOOtymbd5lsuS6XPe6uBUwGUYho8Bh3nul3no3zS5ZLrMW2MyWZpTZ3Km66YYuVfcxb9cNEMfNVlkSiOl7Iw+y5ZpZQvHl+PTMA/DvCx2dmv/FsuSdcSSeV52XVae2lYrQRsdCpIjlxNZDWPAy46b3JSSVmcIJwMiNtHq1uPRrfOyDsvar3hJZ8m6UrJzeY4+4Qyi4s1NPr/uWwi/05jZtjFp3yvw+xobJsK0F9d7zG/N6uqR7TGpNGMZeycECGrowhXuytRZwow6fXFpH2qmWo24bxR/l5X/i+0xynY3wAUqD0yiLxp0ejbyJjRU/Knmc3qQEKxAITbRPZM99b3aUtlhczz10+RCmvPs8HLQOmG6MdQqPPL4fwGnpsbZ6fOQ/yOKqqJSHevqLtNaR5qI9zUfBp+iSYK/y6x78BXNpvuaNOEczTm7N5X/EjSKsRfe9TSV/J+pnqxyzzGO0Xuy1O9UdTQ5BfwEMG3QBA==:54E5^FT266,65^A@N,39,38,ARI001.FNT^FB373,1,10,C^FH^CI28^FDVoucher^FS^CI27^FT266,114^A@N,39,38,ARI001.FNT^FB373,1,10,C^FH^CI28^FDAlmoço - 22/09^FS^CI27^FT1,244^A@N,39,38,ARI001.FNT^FB638,1,10,C^FH^CI28^FD[[NOME_1]]^FS^CI27^PQ1,,,Y^XZ'
                        ]
                        const labelsArray = voucherCodes.map((voucher) => voucher.replaceAll('[[NOME_1]]',
                            `${participant.name} ${
                                !participant.lastName?.toString() ? '' : participant.lastName?.toString().split(' ').at(-1)
                            }`
                        ))
                        
                        devices.printer[0].send(
                            labelsArray.join(''),
                            () => {
                                console.log(labelsArray.join())
                                resolve('Voucher impresso');
                            },
                            () => {
                                reject(new Error('Erro ao imprimir voucher'));
                            },
                        )
                    }
                    else {
                        devices.printer[0].send(
                            zebraCode,
                            () => {
                                resolve('Impressão realizada');
                            },
                            () => {
                                reject(new Error('Erro ao imprimir layout'));
                            },
                        );
                    }
                });
            }
        }
    });

    export const handlePrintAppConfig = async (
        event: EventResponse,
        config: string,
        data: string
    ) =>
        new Promise(async (resolve, reject) => {
            const etiqueta = AppConfigLabel.sizes['7x3']
            const printer = event.printerModel ?? 'ZEBRA'
    
            if (!etiqueta) {
                return reject(new Error('Nenhuma etiqueta configurada'));
            }
            let zebraCode = etiqueta
            
            zebraCode = zebraCode?.replaceAll('[[CONFIG]]', config);
            zebraCode = zebraCode?.replaceAll('[[QRCODE]]', data);
    
    
            if(printer === 'ELGIN') {
                const elgin = 'http://127.0.0.1:2001/eti/v1/write'
                zebraCode = zebraCode?.replaceAll('.FNT','.TTF')
                try{
                    fetch(elgin, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        method: 'POST',
                        body: JSON.stringify({dados: zebraCode})
                    })
                    .then(res => res.json())
                    .then((response) => {
                        if(response.mensagem === 'SUCESSO') {
                            return resolve('Impressão realizada');
                        }
                    })
                } catch (error) {
                    return reject(new Error('Erro ao se comunicar com a impressora'))
                }
            }
    
            if(printer === 'ZEBRA') {
                console.log(zebraCode)
                const BrowserPrint = (window as any).BrowserPrint;
                if (!BrowserPrint) {
                    return reject(new Error('Falha ao se comunicar com a impressora'));
                }
        
                BrowserPrint.getLocalDevices((devices: any) => {
                    if (!devices.printer) {
                        reject(new Error('Erro ao se comunicar com a impressora'));   
                    }
                    devices.printer[0].send(
                        zebraCode,
                        () => {
                            resolve('Impressão realizada');
                        },
                        () => {
                            reject(new Error('Erro ao imprimir layout'));
                        },
                    );
                });
            }
        });
