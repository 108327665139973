import ModalLoading from '@/Components/ModalLoading';
import { useToken } from '@/Components/UseToken';
import ApiService from '@/Services/api/ApiService';
import { EventResponse } from '@/Services/api/models/eventModel';
import {
    CreateParticipantRequest,
    GetParticipantResponse,
} from '@/Services/api/models/participantsModel';
import { handlePrintTicket } from '@/util/print';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Column, GambetaContainer, SignInParticipantContainer, WarnText } from './styled';
import * as yup from 'yup';
import { DashedHR, SubmitButton } from '@/Pages/Events/Adicionar/styled';

import EstadosBrasileiros from '@/util/estadosBrasileiros.json';
import { TButton } from '@/Components/TButton';
import { BackArrow } from '@/Components/ArrowBack';
import { ConfirmModal } from '@/Components/ConfirmModal';
import { useAutoAttendantNavigate } from '@/Components/CustomNavigate/autoattendant';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import { TranslateParticipantFields } from '@/util/translate';
import { UsersResponse } from '@/Services/api/models/usersModel';
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { Room } from '@/Services/api/models/ControleDeAcessoModels';

export interface ParticipantFormValues {
    nome: String;
    sobrenome: String;
    estado: String;
    cidade: String;
    empresa: String;
    cargo: String;
    documento: String;
    telCelular: String;
    telComercial: String;
    email: String;
    ativo: Boolean;
    categoria: String;
}



export function SignInParticipantInAutoAttendant() {
    const location = useLocation();
    const { token } = useToken();
    const [startTimestamp, setStartTimestamp] = useState<Date>()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eventInfos, setEventInfos] = useState<EventResponse>();
    const [backgroundImage, setBackgroundImage] = useState('');
    const [participantCategories, setParticipantCategories] = useState<Array<ParticipantCategories>>([])

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [insertedParticipant, setInsertedParticipant] = useState<GetParticipantResponse>();

    const [errorOnPrint, setErrorOnPrint] = useState(false);
    const { language } = useParams()
    const [english, setEnglish] = useState<boolean>(false)
    const navigate = useAutoAttendantNavigate(location.state.backgroundImage ?? backgroundImage);

    //provisório
    const userInfoString = localStorage.getItem('userInfo');
    const userInfo: UsersResponse = JSON.parse(userInfoString) ?? {}

    useIdleTimer({
        timeout: 40000,
        onIdle: () => {
            if (!errorOnPrint) navigate('/autoattendant/');
        },
    });

    function createYupSchema() {
        const requiredFields = eventInfos?.requiredFields.split(',');
        

        const schema = {
            nome: yup.string().nullable(),
            sobrenome: yup.string().nullable(),
            documento: yup.string().nullable(),
            estado: yup.string().nullable(),
            cidade: yup.string().nullable(),
            empresa: yup.string().nullable(),
            cargo: yup.string().nullable(),
            telCelular: yup.string().nullable(),
            telComercial: yup.string().nullable(),
            email: yup.string().nullable(),
            ativo: yup.boolean().nullable(),
            categoria: yup.string().nullable()
        };
        
        if (!requiredFields) return schema;

        requiredFields.forEach((field) => {
            // @ts-ignore
            if (!schema[field]) {
                return;
            }

            // @ts-ignore
            schema[field] = schema[field].required(english? `Field ${TranslateParticipantFields[field.charAt(0).toUpperCase() + field.slice(1)]} is required` : `${field.charAt(0).toUpperCase()}${field.slice(1)} é obrigatório`)
        })

        return schema
    }

    const validateSchema = yup.object().shape(createYupSchema());

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<ParticipantFormValues>({
        resolver: yupResolver(validateSchema),
    });

    const handleSubmitForm: SubmitHandler<ParticipantFormValues> = async (data) => {
        try {
            setIsLoading(true);
            const createParticipantRequest: CreateParticipantRequest = {
                name: data.nome,
                lastName: data.sobrenome,
                state: data.estado,
                email: data.email,
                city: data.cidade,
                cellPhoneNumber: data.telCelular,
                commercialPhoneNumber: data.telComercial,
                companyName: data.empresa,
                companyPosition: data.cargo,
                documentNumber: data.documento || null,
                eventId: Number(eventInfos?.id),
                registrationOrigin: token?.user?.userGroup ?? '',
                active: true,
                checkinDate: null,
                externalCodeAux: 0,
                hasDivergence: false,
                isImportedFromExcel: false,
                categoryId: 1,
                linkOrigin: null,
                additionalInformation: null,
                additionalCategory: null,
                checkInDuration: 0         
            };

            const participant = await ApiService.upInsertParticipant(createParticipantRequest);
            setInsertedParticipant(participant);

            if (eventInfos?.accessControl && eventInfos?.additionalCategories) {
                try {
                    const rooms:Room[] = await ControleDeAcessoService.getRoomsByEvent(eventInfos?.id);
                    if (rooms) {
                        const registrationPromises = rooms.map(async (r) => {
                            if (r.allowed_categories?.includes(participant?.additionalCategory?.trim())) {
                                return ControleDeAcessoService.registerParticipant({
                                    rentpass_participant: participant?.id,
                                    room: r?.id,
                                    participant_category: participant?.additionalCategory,
                                    group: r?.group
                                });
                            }
                        });
        
                        const responses = await Promise.all(registrationPromises);
                    }
                } catch (error) {
                    toast.error('Houve um erro ao cadastrar o participante nas salas')
                    console.error('Error handling registration:', error);
                }
            }

            eventInfos?.checkinType === "CONFIRMATION_REQUIRED"? setShowConfirmModal(true) : handleCheckIn(participant);
        } catch (e: any) {
            toast.error(e.response.data.message ?? 'Erro desconhecido');
        } finally {
            setIsLoading(false);
        }
    };

    const getEventInfo = async () => {
        try {
            setIsLoading(true);
            const event = await ApiService.getEventById(userInfo?.events?.[0] ?? 0);
            setEventInfos(event);
            console.log(event)
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const loadEventImage = async () => {
        try {
            setIsLoading(true);
            const backgroundImage = await ApiService.getBackgroundImage(userInfo?.events?.[0] ?? 0);
            setBackgroundImage(backgroundImage);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getParticipantCategories = async () => {
        const categories = await ApiService.getParticipantCategories()
        setParticipantCategories(categories)
        setValue('categoria','Participante')
    }

    const handleCheckIn = async (participant:GetParticipantResponse) => {
        try{
            setIsLoading(true)
            const d = new Date();
            const endTimestamp =  d.getTime() - startTimestamp.getTime()
            d.setHours(d.getHours() - 3);
            await ApiService.checkInParticipant(participant.id, d.toISOString(), endTimestamp)
            if(eventInfos.labelConfiguration !== 'No label') { 
                await handlePrintTicket(eventInfos, participant) 
            };
            toast.success(english? 'Credential printed successfully' : 'Credencial impressa com sucesso');
        }
        catch (e: any) {
            toast.error(e.message);
        }
        finally {
            setIsLoading(false)
            navigate(`/autoattendant`);
        }
    }

    useEffect(() => {
        setStartTimestamp(new Date())
        getEventInfo();
        loadEventImage();
        getParticipantCategories();
        setEnglish(language === 'en')
    }, []);
    
    return (
        <GambetaContainer backgroundUrl={location.state?.backgroundImage ?? backgroundImage}>
            <SignInParticipantContainer
                backgroundUrl={location.state?.backgroundImage ?? backgroundImage}
            >
                <BackArrow />
                <ModalLoading isActive={isLoading} />
                <ConfirmModal
                    show={showConfirmModal}
                    english={english}
                    onClose={() => {
                        navigate(`/autoattendant/checkin/${english? 'en' : 'pt'}`);
                        toast.success(english? 'Registration successful!' : 'Cadastrado com sucesso! realize seu checkIn');
                    }}
                    onConfirm={async () => {
                        try {
                            setIsLoading(true);
                            if (!eventInfos || !insertedParticipant) return;
                            await handlePrintTicket(eventInfos, insertedParticipant);
                            setIsLoading(false);

                            toast.success(english? 'Credential printed successfully' : 'Credencial impressa com sucesso');
                            navigate(`/autoattendant/${english? 'en' : 'pt'}`);
                        } catch (e) {
                            toast.error(english? 'A problem occured' : 'Não foi possível imprimir a credencial');
                        }
                    }}
                />
                <Form onSubmit={handleSubmit(handleSubmitForm)}>
                    {eventInfos?.requiredFields.includes('nome') &&
                        <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    {eventInfos?.requiredFields.includes('nome')? '*' : ''}
                                    {english? 'Name' : 'Nome'}
                                </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('nome')} type='text' />
                                {errors.nome?.message && <WarnText> {errors.nome.message} </WarnText>}
                            </Col>
                        </Form.Group>
                    }   
                    {eventInfos?.requiredFields.includes('sobrenome') && 
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {eventInfos?.requiredFields.includes('sobrenome')? '*' : ''}
                                {english? 'Last name' : 'Sobrenome'}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('sobrenome')} type='text' />
                                {errors.sobrenome?.message && (
                                    <WarnText> {errors.sobrenome.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                    }
                    {eventInfos?.requiredFields.includes('documento') &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {eventInfos?.requiredFields.includes('documento')? '*' : ''}
                                {english? 'Document number' : eventInfos?.details !== ''? eventInfos?.details : 'Documento'}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('documento')} type='text' />
                                {errors.documento?.message && (
                                    <WarnText> {errors.documento.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                    }
                    {eventInfos?.requiredFields.includes('estado') && 
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {eventInfos?.requiredFields.includes('estado')? '*' : ''}
                                {english? 'State' : 'Estado' }
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Select aria-label='Default select example' {...register('estado')}>
                                    <option value={''}> -- Selecione -- </option>
                                    {EstadosBrasileiros.map((item) => (
                                        <option key={item.sigla} value={item.sigla}>
                                            {' '}
                                            {item.sigla} - {item.nome}{' '}
                                        </option>
                                    ))}
                                </Form.Select>

                                {errors.estado?.message && <WarnText> {errors.estado.message} </WarnText>}
                            </Col>
                        </Form.Group>
                    }
                    {eventInfos?.requiredFields.includes('cidade') &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {eventInfos?.requiredFields.includes('cidade')? '*' : ''}
                                {english? 'City' : 'Cidade'}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('cidade')} type='text' />
                                {errors.cidade?.message && <WarnText> {errors.cidade.message} </WarnText>}
                            </Col>
                        </Form.Group>
                    }
                    {eventInfos?.requiredFields.includes('empresa') &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {eventInfos?.requiredFields.includes('empresa')? '*' : ''}
                                {english? 'Company': 'Empresa'}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('empresa')} type='text' />
                                {errors.empresa?.message && (
                                    <WarnText> {errors.empresa.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                    }
                    {eventInfos?.requiredFields.includes('cargo') &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {eventInfos?.requiredFields.includes('cargo')? '*' : ''}
                                {english? 'Company Position' : 'Cargo'}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('cargo')} type='text' />
                                {errors.cargo?.message && (
                                    <WarnText> {errors.cargo.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                    }
                    {eventInfos?.requiredFields.includes('telCelular') &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {eventInfos?.requiredFields.includes('telCelular')? '*' : ''}
                                {english? 'Mobile' : 'Tel Celular'}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('telCelular')} type='text' />
                                {errors.telCelular?.message && <WarnText> {errors.telCelular.message} </WarnText>}
                            </Col>
                        </Form.Group>
                    }
                    {eventInfos?.requiredFields.includes('telComercial') &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {eventInfos?.requiredFields.includes('telComercial')? '*' : ''}
                                {english? 'Phone' : 'Tel Comercial'}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('telComercial')} type='text' />
                                {errors.telComercial?.message && (
                                    <WarnText> {errors.telComercial.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                    }
                    {eventInfos?.requiredFields.includes('email') &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {eventInfos?.requiredFields.includes('email')? '*' : ''}
                                E-mail
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('email')} type='text' />
                                {errors.email?.message && <WarnText> {errors.email.message} </WarnText>}
                            </Col>
                        </Form.Group>
                    }


                    <SubmitButton>
                        <TButton type='submit' width={'500px'}>
                            {english? 'Submit' : 'Cadastrar'}
                        </TButton>
                    </SubmitButton>
                </Form>
            </SignInParticipantContainer>
        </GambetaContainer>
    );
}
