import axios, { AxiosError, AxiosResponse } from 'axios';
import { CreateEventRequest, EventResponse, FilterOptions, IMetrics } from './models/eventModel';
import { CreateParticipantRequest, GetParticipantResponse } from './models/participantsModel';
import { SignInRequest, SignInResponse } from './models/signInModel';
import { CreateUserRequest, UsersResponse } from './models/usersModel';
import { ParticipantCategories } from './models/participantCategoryModel';
import { CekResponse, cekRequest } from './models/cekModels';
import { MalexRequest, MalexResponse } from './models/malexModels';
import { Attraction, AttractionParticipantCheckIn, AttractionRequest, AttractionSummary } from './models/attractionsModels';
import { DefaultResponseRequest, DefaultResponseResponse, QuestionRequest, QuestionResponse, ResponseRequest, ResponseResponse } from './models/surveyModels';

// https://rentpassapi.rentsolution.net:8443/rentpass/
// http://localhost:8080/

let configuration = {
    baseURL: 'https://rentpassapi.rentsolution.net:8443/rentpass/'
};

export default class ApiService {
    private static apiServiceAxios = axios.create(configuration);

    static loginData: { token?: string } = {
        token: undefined,
    };

    static setTokenUsuarioAutenticado(token: string) {
        this.apiServiceAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        this.loginData.token = token;
    }

    private static enviarRequestAsync = async <T>(
        verb: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
        path: string,
        data: any = undefined,
        auth?: any,
    ): Promise<AxiosResponse<T, any>> => {
        const tokenString = localStorage.getItem('token');

        if (tokenString) {
            const userToken: SignInResponse = JSON.parse(tokenString);
            this.setTokenUsuarioAutenticado(userToken.token);
        }

        const headers = {
            'ngrok-skip-browser-warning': 'any'
        };

        try {
            switch (verb) {
                case 'GET':
                    return this.apiServiceAxios.get<T>(path, {
                        params: data,
                        headers: headers,
                    });
                case 'PUT':
                case 'PATCH':
                case 'POST':
                    return this.apiServiceAxios({
                        method: verb,
                        data: data,
                        url: path,
                        auth: auth,
                    });
                case 'DELETE':
                    return this.apiServiceAxios({
                        method: verb,
                        data: data,
                        headers: headers,
                        url: path,
                    });

                default:
                    throw new Error('O verbo usado na requisição não é suportado pelo sistema.');
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response) {
                    // O servidor respondeu com um status code diferente de 200
                    if (error.response.data.erros.length > 0) {
                        throw new Error(error.response.data.erros[0].mensagem);
                    }
                    throw new Error('Ocorreu um erro ao processar sua solicitação.');
                } else if (error.request) {
                    // A requisição foi feita, porém não foi recebida nenhuma resposta do servidor
                    throw new Error('Não foi possível se comunicar com o servidor.');
                } else {
                    // Um erro ocorreu antes do envio da requisição
                    throw error;
                }
            } else {
                throw error;
            }
        }
    };

    public static async autenticarUsuarioAsync(
        autenticarModel: SignInRequest,
    ): Promise<SignInResponse> {
        var response = await this.enviarRequestAsync<SignInResponse>(
            'POST',
            `/user/authenticate`,
            {},
            {
                username: autenticarModel.email,
                password: autenticarModel.password,
            },
        );

        this.apiServiceAxios.interceptors.request.use((config) => {
            config.headers = {
                Authorization: `Bearer ${response.data.token}`,
            };
            return config;
        });

        return response.data;
    }

    public static async getAllEventos(): Promise<EventResponse[]> {
        const response = await this.enviarRequestAsync<EventResponse[]>('GET', '/event/');

        return response.data;
    }

    public static async getEventosBySpecification(data: FilterOptions): Promise<EventResponse[]> {
        const response = await this.enviarRequestAsync<EventResponse[]>('GET', '/event/', data);

        return response.data;
    }

    public static async getAllUsers(): Promise<UsersResponse[]> {
        const response = await this.enviarRequestAsync<UsersResponse[]>('GET', '/user/');

        return response.data;
    }

    public static async getUserByID(id: number): Promise<UsersResponse> {
        const response = await this.enviarRequestAsync<UsersResponse>('GET', '/user/' + id);

        return response.data;
    }

    public static async upInsertUser(user: CreateUserRequest): Promise<any> {
        const response = await this.enviarRequestAsync<any>('PUT', '/user/', user);

        return response.data;
    }

    public static async DeleteUser(id: number) {
        const response = await this.enviarRequestAsync<any>('DELETE', `/user/${id}`);

        return response.data;
    }

    public static async UpdateUserPassword(user: CreateUserRequest) {
        const response = await this.enviarRequestAsync<any>('PATCH', `/user/changePassword/`, user);

        return response.data;
    }

    public static async getEventById(id: number): Promise<EventResponse> {
        const response = await this.enviarRequestAsync<EventResponse>('GET', '/event/' + id);

        return response.data;
    }

    public static async upInsertEvent(event: CreateEventRequest): Promise<any> {
        const response = await this.enviarRequestAsync<any>('PUT', '/event/', event);

        return response.data;
    }

    public static async getAllParticipantsByEvent(
        eventID: number,
    ): Promise<GetParticipantResponse[]> {
        const response = await this.enviarRequestAsync<GetParticipantResponse[]>(
            'GET',
            '/participant/event/' + eventID,
        );

        return response.data;
    }

    public static async deleteParticipant(id: number) {
        const response = await this.enviarRequestAsync<string>('DELETE', `/participant/${id}`);

        return response.data;
    }

    public static async getParticipantByID(id: number): Promise<GetParticipantResponse> {
        const response = await this.enviarRequestAsync<GetParticipantResponse>(
            'GET',
            '/participant/' + id,
        );

        return response.data;
    }

    public static async upInsertParticipant(participant: CreateParticipantRequest) {
        const response = await this.enviarRequestAsync<GetParticipantResponse>(
            'PUT',
            '/participant/',
            participant,
        );
        return response.data;
    }

    public static async upInsertParticipantExcel(participant: CreateParticipantRequest) {
        const response = await this.enviarRequestAsync('POST', '/participant/excel', participant);

        return response.data;
    }

    public static async checkInParticipant(id: number, checkinDate: string, checkInDuration: number) {
        const response = await this.enviarRequestAsync('PUT', `/participant/checkin`, {
            checkinDate: checkinDate,
            id: id,
            checkInDuration: checkInDuration
        });

        return response.data;
    }

    public static async getDivergencyParticipantsByEvent(eventId: number): Promise<string[]> {
        const response = await this.enviarRequestAsync<string[]>(
            'GET',
            `/participant/event/${eventId}/divergences`,
        );

        return response.data;
    }

    public static async getBackgroundImage(eventId: number): Promise<string> {
        const response = await this.enviarRequestAsync<string>(
            'GET',
            `/event/background-image/${eventId}`,
        );

        return response.data;
    }

    public static async getEventMetrics(eventId: number) {
        const response = await this.enviarRequestAsync<IMetrics>(
            'GET',
            `/event/follow-up/${eventId}`,
        );

        return response.data;
    }

    public static async updateImageCache() {
        const response = await this.enviarRequestAsync<IMetrics>('GET', `/event/refresh-images`);

        return response.data;
    }

    public static async getParticipantCategories() {
        const response = await this.enviarRequestAsync<Array<ParticipantCategories>>('GET', '/category/');
        return response.data;
    }

    public static async insertParticipantCategory(categoryName: string, id: number) {
        const response = await this.enviarRequestAsync<Array<ParticipantCategories>>('POST', '/category/',{
            categoryName: categoryName,
            id: id,
        });
        return response.data;
    }

    public static async deleteParticipantCategory(id: number) {
        const response = await this.enviarRequestAsync<Array<ParticipantCategories>>('DELETE', `/category/${id}`)
        return response.data;
    }

    public static async bulkInsertParticipants(data: Array<CreateParticipantRequest>) {
        const response = await this.enviarRequestAsync(
            'POST', 
            `/participant/bulkInsert`,
            data
        )
        return response.data;
    }

    public static async getCekList(eventId: number) {
        const response = await this.enviarRequestAsync<CekResponse[]>(
            'GET', 
            `/event/participantCekList/${eventId}`
        )
        return response.data;
    }

    public static async cekWithdraw(data: cekRequest) {
        const response = await this.enviarRequestAsync<CekResponse>(
            'POST', 
            `/participant/cek/`,
            data
        )
        return response.data;
    }

    public static async malexGetByEvent(eventId: number) {
        const response = await this.enviarRequestAsync<MalexResponse[]>(
            'GET',
            `/malex/event/${eventId}`
        )
        return response.data;
    }

    public static async malexUpInsert(data: MalexRequest) {
        const response = await this.enviarRequestAsync<MalexResponse[]>(
            'PUT',
            `/malex/`,
            data
        )
        return response.data;
    }

    public static async malexDelete(id: number) {
        const response = await this.enviarRequestAsync<MalexResponse[]>(
            'DELETE',
            `/malex/${id}`
        )
        return response.data;
    }

    public static async attractionUpInsert(data: AttractionRequest) {
        const response = await this.enviarRequestAsync<Attraction>(
            'PUT',
            '/attraction/',
            data
        )
        return response.data;
    }

    public static async attractionFindById(attractionId: number) {
        const response = await this.enviarRequestAsync<Attraction>(
            'GET',
            `/attraction/${attractionId}`,
        )
        return response.data;
    }

    public static async attractionDelete(attractionId: number) {
        const response = await this.enviarRequestAsync<Attraction>(
            'DELETE',
            `/attraction/${attractionId}`,
        )
        return response.data;
    }

    public static async attractionFindByEvent(eventId: number) {
        const response = await this.enviarRequestAsync<Attraction[]>(
            'GET',
            `/attraction/event/${eventId}`,
        )
        return response.data;
    }

    public static async attractionFindByUser(userId: number) {
        const response = await this.enviarRequestAsync<Attraction[]>(
            'GET',
            `/attraction/user/${userId}`,
        )
        return response.data;
    }

    public static async attractionParticipantCheckIn(data: AttractionParticipantCheckIn) {
        const response = await this.enviarRequestAsync<AttractionParticipantCheckIn>(
            'POST',
            `/attraction/AttractionParticipantCheckIn`,
            data
        )
        return response.data;
    }

    public static async attractionParticipantList(attractionId: number) {
        const response = await this.enviarRequestAsync<AttractionParticipantCheckIn[]>(
            'GET',
            `/attraction/participantCheckIn/${attractionId}`
        )
        return response.data;
    }

    public static async attractionSummary(attractionId: number) {
        const response = await this.enviarRequestAsync<AttractionSummary>(
            'GET',
            `/attraction/summary/${attractionId}`
        )
        return response.data;
    }

    public static async insertQuestion(data: QuestionRequest) {
        const response = await this.enviarRequestAsync<QuestionResponse>(
            'POST',
            `/question/`,
            data
        )
        return response.data;
    }

    public static async getQuestionsByEventId(eventID: number) {
        const response = await this.enviarRequestAsync<QuestionResponse[]>(
            'GET',
            `/question/event/${eventID}/`
        )
        return response.data;
    }

    public static async deleteQuestion(questionID: number) {
        const response = await this.enviarRequestAsync<any>(
            'DELETE',
            `/question/${questionID}/`
        )
        return response.data;
    }

    public static async bulkInsertDefaultResponse(data: DefaultResponseRequest[]) {
        const response = await this.enviarRequestAsync<DefaultResponseResponse[]>(
            'POST',
            `/question/defaultResponse/bulkInsert`,
            data
        )
        return response.data;
    }

    public static async bulkInsertResponse(data: ResponseRequest[]) {
        const response = await this.enviarRequestAsync<ResponseResponse[]>(
            'POST',
            `/question/response/bulkInsert`,
            data
        )
        return response.data;
    }

    public static async getAllResponsesByQuestionId(questionId: number) {
        const response = await this.enviarRequestAsync<ResponseResponse[]>(
            'GET',
            `/question/response/question/${questionId}`
        )
        return response.data;
    }

    public static async getAllDefaultResponsesByQuestionId(questionId: number) {
        const response = await this.enviarRequestAsync<DefaultResponseResponse[]>(
            'GET',
            `/question/defaultResponse/question/${questionId}`
        )
        return response.data;
    }
}
