import styled from 'styled-components';
import { Title } from '../Homepage/styled';
import { Form } from 'react-bootstrap';
import { TButton } from '@/Components/TButton';

interface CheckInProps {
    backgroundUrl: string;
}

export const CheckInContainer = styled.div<CheckInProps>`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    background-image: url(${(props) => props.backgroundUrl});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: right;
    }

    .form-label {
        text-align: left;
        width: 100%;
    }

    .form-control {
        width: 500px;
        height: 60px;

        color: transparent;
        text-shadow: 0 0 0 ${({ theme }) => theme.colors.black};
    }
`;

export const SearchByContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 10px;
    border-radius: 30px;

    background: rgb(0 0 0 / 50%);
    min-height: 25vh;
    width: 800px;
`;

export const CheckInForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const FormLabel = styled(Form.Label)`
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.fontSize['xl']};
`;

export const CheckoutButton = styled(TButton)`
    width: 300px;
    height: 85px;
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    border-radius: 30px
`;

export const CheckInTitle = styled(Title)`
    margin-top: 0px;
    font-size: ${({ theme }) => theme.fontSize['3xl']};
`;

export const ErrorMessage = styled(Title)`
    margin-top: 0px;
    font-size: ${({ theme }) => theme.fontSize['2xl']};
`;

export const KeyboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 800px;
    opacity: 70%;
`

export const bold = styled.div`
    font-weight: bold;
`

export const PaymentContainer = styled.div`
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
    color: #fff
`
export const QRCodeContainer = styled.div`
    border-radius: 30px;
    background-color: #FFF;
    display: flex;
    height: 250px;
    width: 250px;
    align-items: center;
    justify-content: center;
`